import * as dompack from "dompack";
import * as preload from 'dompack/extra/preload';
import getTid from "@mod-tollium/js/gettid";
import FormBase from "@mod-publisher/js/forms/formbase";
import FileEditBase from '@mod-publisher/js/forms/fields/fileeditbase';
import "./canvasupload.scss";
import cSlider from "./slider.es";

export default class CanvasUpload extends FileEditBase
{
  constructor(node, options)
  {
    super(node, options);

    this.name = this.node.dataset.whFormName;

    // Initialize node references
    this.node = node;
    this.canvasNode = dompack.qS(node, ".canvasupload__input");

    this.fieldgroupnode = dompack.closest(this.node, ".wh-form__fieldgroup");

    this.node.addEventListener('click', ev => {
      if( this.imgNode || this.fieldgroupnode.classList.contains("wh-form--uploading") )
      {
        ev.preventDefault();
        return;//already has image or is loading
      }
      this.selectFile(ev);
    });

    if (window.FileReader)
    {
      this.node.addEventListener("dragover", evt => evt.preventDefault());
      this.node.addEventListener("dragenter", evt => evt.preventDefault());
      this.node.addEventListener("drop", evt => this.doDrop(evt));
    }

    this.canvassize = { x: 1*this.node.dataset.imagewidth
                      , y: 1*this.node.dataset.imageheight
                      };
    this.imagesize = null;

    this.canvasNode.width = this.canvassize.x;
    this.canvasNode.height = this.canvassize.y;
    this.canvasContext = this.canvasNode.getContext("2d");

    let aspect = this.canvassize.y*100 / this.canvassize.x;
    this.imgContainer = dompack.qS(this.node, ".canvasupload__imgcontainer");
    this.imgContainer.style.paddingTop = aspect + "%";

    this.clearNode = dompack.qS(this.node, ".canvasupload__clear");
    // Clear the field on clicking the clear button
    this.clearNode.addEventListener("click", event => this._clear(event));

    let hasUploadValue = this.node.dataset.canvasUploadLink;

    // Field initialization
    this.isRequired = !hasUploadValue && this.node.dataset.canvasUploadRequired == "true";
    this._updateEnabledStatus(this.node.dataset.canvasUploadDisabled != "true");
    this.node.whFormsApiChecker = () => this._check();
    this.node.whUseFormGetValue = true;
    this.node.addEventListener("wh:form-getvalue", event => this.getValue(event));

    // Initialize fieldgroup
    this._fieldgroup = dompack.closest(this.node,".wh-form__fieldgroup");
    if (this._fieldgroup)
    {
      this.node.dataset.whFormEnableListener = true;

      // ADDME: for now, we're not worrying about dynamic disabling/requiring
      // this.node.addEventListener("wh:form-enable", event => this._handleEnable(event));
      // this.node.addEventListener("wh:form-require", event => this._handleRequire(event));
    }

    //Setup scale control
    let scalenode = this.node.querySelector(".wh-slider-holder");
    this.sliderobj = new cSlider( scalenode, { startvalues : [0] } );
    node.addEventListener("sliderchange", ev => {
      // range is from this.minscale .. 1
      if( this.minscale >= 1 )
        return;

      let prevscale = this.transform.scale;
      this.transform.scale = this.minscale + (1 - this.minscale)*ev.detail.value*0.01;

      // compensate x/y movement caused by scaling
      let centerx = (this.imagesize.x/2) - this.transform.x;
      let dx = centerx*prevscale - centerx*this.transform.scale;
      this.transform.x += dx;

      let centery = (this.imagesize.y/2) - this.transform.y;
      let dy = centery*prevscale - centery*this.transform.scale;
      this.transform.y += dy;

      this.checkImageBounds();

      this.setImageTransform();
    });

    this.isOriginalImage = false;

    // If there already is a value, show it
    if ( hasUploadValue )
      this.setInitialImage();
    else
      this._clear();

    window.addEventListener("mouseup", ev => this.endDrag(ev));
    if(this.touchEnabled())
      window.addEventListener("touchend", ev => this.endDrag(ev));

    window.addEventListener("resize", ev => this.onResize());
  }

  async setInitialImage()
  {
    this.setValue();
    this.node.classList.add('canvasupload--disabled');

    this.fieldgroupnode.classList.add("wh-form--uploading");
    let imgpreload = await preload.promiseImage(this.node.dataset.canvasUploadLink);
    this.fieldgroupnode.classList.remove("wh-form--uploading");

    if(!imgpreload.width || !imgpreload.height)
      return;

    this.imagesize = { x: 1*this.node.dataset.canvasUploadWidth
                     , y: 1*this.node.dataset.canvasUploadHeight
                     };

    this.imgNode = <img src={this.node.dataset.canvasUploadLink} width={this.imagesize.x} height={this.imagesize.y} class="canvasupload__img" />;
    this.imgNode.style.width  = this.imagesize.x + "px";
    this.imgNode.style.height = this.imagesize.y + "px";

    this.imgContainer.appendChild(this.imgNode);
    this.clearNode.style.display = this.isEnabled ? "" : "none";

    this.isOriginalImage = true;

    this.initImage();
    this.positionImage();
  }

  onResize()
  {
    this.sliderobj.refresh();
    this.positionImage();
  }

  doDrop(evt)
  {
    evt.preventDefault();

    if( this.imgNode || this.fieldgroupnode.classList.contains("wh-form--uploading") )
    {
      return;//already has image or is loading
    }

    let lock = dompack.flagUIBusy();
    let files = evt.dataTransfer.files;
    this.uploadFile(files, lock);
  }

  initImage()
  {
    //Initial fillout container
    let scalex = this.canvassize.x / this.imagesize.x;
    let scaley = this.canvassize.y / this.imagesize.y;
    this.minscale = (scalex > scaley ? scalex : scaley);

    this.hasValue = true;
    this.canvasNode.style.width = this.canvassize.x + "px";
    this.canvasNode.style.height = this.canvassize.y + "px";

    this.imgNode.onload = function() {
      this.canvasContext.drawImage(this.imgNode, this.transform.x, this.transform.y, this.imagesize.x*this.transform.scale, this.imagesize.y*this.transform.scale);
    }.bind(this);

    if( this.minscale < 1 )
      this.node.classList.add('canedit');
    else
      this.node.classList.remove('canedit');

    this.sliderobj.setValues([0]);

    if( this.minscale > 1 )
      this.minscale = 1;

    this.transform = { "scale" : this.minscale
                     , "x"     : (this.canvassize.x - this.imagesize.x*this.minscale)/2
                     , "y"     : (this.canvassize.y - this.imagesize.y*this.minscale)/2
                     };

    if( this.node.dataset.transformsettings != "" )
    {
      let prevtransform = JSON.parse(this.node.dataset.transformsettings);

      if( prevtransform.scale && prevtransform.scale > this.minscale && prevtransform.scale <= 1 )
      {
        this.transform.scale = prevtransform.scale;
        //Set slider
        let sliderval = (this.transform.scale - this.minscale) * 100 / (1 - this.minscale);
        this.sliderobj.setValues([ sliderval ]);
      }

      if( prevtransform.x <= 0 )
        this.transform.x = prevtransform.x;
      if( prevtransform.y  <= 0 )
        this.transform.y = prevtransform.y;
    }

    this.initialtransform = JSON.stringify(this.transform);

    if( this.imagesize.x < this.canvassize.x || this.imagesize.y < this.canvassize.y )
      return;//no resizing/pan option

    if( this.imagesize.x > this.canvassize.x || this.imagesize.y > this.canvassize.y )
    {//width or height larger then canvas, then add controls
      //Drag control
      this.imgNode.addEventListener("mousedown", ev => this.startDrag(ev));
      this.imgNode.addEventListener("mousemove", ev => this.onDrag(ev) );
      if(this.touchEnabled())
      {
        this.imgNode.addEventListener("touchstart", ev => this.startDrag(ev));
        this.imgNode.addEventListener("touchmove", ev => this.onDrag(ev));
      }
    }
  }

  startDrag(ev)
  {
    ev.preventDefault();
    this.drag = { x0 : ev.pageX
                , y0 : ev.pageY
                , x  : 0
                , y  : 0
                };
  }

  onDrag(ev)
  {
    if( !this.drag )
      return;

    ev.preventDefault();

    this.drag.x = ev.pageX - this.drag.x0;
    this.drag.y = ev.pageY - this.drag.y0;

    this.drag.x0 = ev.pageX;
    this.drag.y0 = ev.pageY;

    this.transform.x += this.drag.x/this.screen.scale;
    this.transform.y += this.drag.y/this.screen.scale;

    this.checkImageBounds();

    this.setImageTransform();
  }

  touchEnabled()
  {
    let enable = "ontouchstart" in window || "createTouch" in document || "TouchEvent" in window ||
                 (window.DocumentTouch && document instanceof window.DocumentTouch) ||
                 navigator.maxTouchPoints > 0 ||
                 window.navigator.msMaxTouchPoints > 0;
    return enable;
  }

  checkImageBounds()
  {
    //Check bounds, image must always outfill area
    let minx = this.canvassize.x - this.imagesize.x*this.transform.scale;
    let miny = this.canvassize.y - this.imagesize.y*this.transform.scale;
    if( this.transform.x > 0 )
      this.transform.x = 0;
    else if( this.transform.x < minx )
      this.transform.x = minx;

    if( this.transform.y > 0 )
      this.transform.y = 0;
    else if( this.transform.y < miny )
      this.transform.y = miny;
  }

  endDrag(ev)
  {
    if( !this.drag )
      return;

    this.drag = null;
  }

  positionImage()
  {
    let rect = this.imgContainer.getBoundingClientRect();
    this.screen = { "scale" : rect.width / this.canvassize.x
                  , "width" : rect.width
                  , "height": rect.height
                  };
    if( this.imgNode )
      this.setImageTransform();
  }

  setImageTransform()
  {
    let translate = "translate(" + this.screen.scale*this.transform.x + "px," + this.screen.scale*this.transform.y + "px) ";
    let scale = "scale(" + this.screen.scale*this.transform.scale + "," + this.screen.scale*this.transform.scale + ")";
    this.imgNode.style.transform = translate + " " + scale;

    //First clear canvas
    this.canvasContext.clearRect(0, 0, this.canvasNode.width, this.canvasNode.height);
    this.canvasContext.drawImage(this.imgNode, this.transform.x, this.transform.y, this.imagesize.x*this.transform.scale, this.imagesize.y*this.transform.scale);

    this.isDirty = !this.isOriginalImage || JSON.stringify(this.transform) != this.initialtransform;
  }

  getFieldValueLink()
  {
    return this.imgNode ? this.imgNode.src : "";
  }

  async handleUploadedFile(result)
  {
    //ADDME maxsize? files[0].size....

    if( !result.type || result.type.indexOf("image/") != 0 )
      return;//Not an image

    //Store info from upload result in hidden input
    // so we can retrieve original file later
    this.node.dataset.upload = JSON.stringify(result);

    /* We MUST work through the server to get proper JPEG rotation fixes. So we
       can't just take a dataurl and preview it immediately (not without parsing EXIF)
       until we'd support image-editor integration */

    let imgpreload = await preload.promiseImage(result.url);
    if(!imgpreload.width || !imgpreload.height)
      return;

    dompack.empty(this.imgContainer);
    this.imgNode = <img src={imgpreload.src} width={imgpreload.width} height={imgpreload.height} class="canvasupload__img" />;
    this.imgContainer.appendChild(this.imgNode);

    //Disable upload
    this.node.classList.add('canvasupload--disabled');

    //show clear button
    this.clearNode.style.display = this.isEnabled ? "" : "none";

    this.imagesize = { x: imgpreload.width
                     , y: imgpreload.height
                     };

    if( this.imagesize.x < this.canvassize.x || this.imagesize.y < this.canvassize.y )
      FormBase.setFieldError(this.node, "Image to small (minimal " + this.canvassize.x + " x " + this.canvassize.y + "px)", { reportimmediately: false });

    //Initial fillout container
    this.initImage();
    this.positionImage();//reposition/transform image

    //FIXME this is just a webserver temporary session, need to get URLs with longer persistence
  }

  _clear(event)
  {
    if (event)
      dompack.stop(event);

    this.sliderobj.setValues([0]);

    // Clear state
    this.isDirty = false;
    this.hasValue = false;

    // Clear drawing
    this.canvasContext.clearRect(0, 0, this.canvasNode.width, this.canvasNode.height);

    //Set transparent pixel
    dompack.empty(this.imgContainer);
    this.imgNode = null;

    //Empty hidden fields
    this.node.dataset.upload = "";
    this.node.dataset.transformsettings = "";

    // Hide clear button
    this.clearNode.style.display = "none";

    this.node.dataset.canvasUploadLink = "";

    //show upload option
    this.node.classList.remove("canvasupload--disabled");
    this.node.classList.remove("canedit");
  }

  _check()
  {
    //Set final transform settings
    this.node.dataset.transformsettings = JSON.stringify( this.transform );

    if (this.isRequired && !this.hasValue && !this.isDirty)
      FormBase.setFieldError(this.node, getTid("publisher:site.forms.commonerrors.required"), { reportimmediately: false });
    else if(this.hasValue && ( this.imagesize.x < this.canvassize.x || this.imagesize.y < this.canvassize.y ) )
      FormBase.setFieldError(this.node, "Image to small (minimal " + this.canvassize.x + " x " + this.canvassize.y + "px)", { reportimmediately: false });
    else
      FormBase.setFieldError(this.node, "", { reportimmediately: false });
  }

  // _handleEnable(event)
  // {
  //   dompack.stop(event);
  //   this._updateEnabledStatus(event.detail.enabled);
  // }

  // _handleRequire(event)
  // {
  //   dompack.stop(event);
  //   this.isRequired = event.detail.required;
  // }

  _updateEnabledStatus(enabled)
  {
    if (enabled != this.isEnabled)
    {
      this.isEnabled = enabled;
      this.clearNode.style.display = this.isEnabled && (this.isDirty || this.hasValue) ? "" : "none";

      //ADDME: Enable/disable canvas drawing stuff based on this.isEnabled
    }
  }

  setValue()
  { // Set state
    this.isDirty = false;
    this.hasValue = true;
  }

  getValue(event)
  {
    event.preventDefault();
    event.stopPropagation();

    if (this.hasValue /* this.isDirty*/ )
    {
      event.detail.deferred.resolve({ link: this.hasValue ? this.canvasNode.toDataURL() : ""
                                    , filename: this.name + ".png"
                                    , transformsettings: this.node.dataset.transformsettings
                                    , upload: this.node.dataset.upload
                                    });
    }
    else
    { // The field is empty
      event.detail.deferred.resolve(null);
    }
  }
}
