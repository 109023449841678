import "./login.scss";
import * as wrdauth from '@mod-wrd/js/auth';
import * as dompack from 'dompack';

dompack.register(".auth-menu__logout", node => {
  node.addEventListener("click", ev => {
    wrdauth.getDefaultAuth().logout();
  });
});

window.addEventListener("wh:wrdauth-loginfailed", evt =>
{
  evt.preventDefault();

  if ( evt.detail.code == "inactive" )
    evt.detail.message = "Your registration if not confirmed.\nPlease check your email.";

  alert(evt.detail.message);
});
