import "./footer.scss";
import * as dompack from 'dompack';

class cFooterColumn
{
  constructor(node)
  {
    this.node = node;
    this.titlenode = node.querySelector(".col__title");
    this.textnode = node.querySelector(".col__text");

    this.titlenode.addEventListener("click", ev => this.toggleContent());
  }

  toggleContent()
  {
    clearTimeout(this.timer);

    if( this.node.clientWidth + 50 < this.node.parentNode.clientWidth )
      return;//no one column layout

    if( this.node.classList.contains("active") )
    {
      this.textnode.style.maxHeight = this.textnode.clientHeight + "px";
      this.node.classList.remove("active");
      this.textnode.clientWidth;//force css update
      this.textnode.style.maxHeight = "0";

      this.timer = setTimeout(() => { this.textnode.style.maxHeight = ""; }, 300);
    }
    else
    {
      let h = 0;
      for( let i = 0; i < this.textnode.children.length; ++i )
        h += this.textnode.children[i].clientHeight;

      this.textnode.style.maxHeight = "0";
      this.node.classList.add("active");
      this.textnode.clientWidth;//force css update
      this.textnode.style.maxHeight = h + "px";

      this.timer = setTimeout(() => { this.textnode.style.maxHeight = ""; }, 300);
    }
  }
}

dompack.register("footer .columns > .col--foldable", node => new cFooterColumn(node));
