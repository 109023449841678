import "./registration.scss";
import { RPCFormBase, registerHandler } from '@mod-publisher/js/forms';
import * as dompack from 'dompack';
import CanvasUploadField from '@mod-kiskeya/formcomponents/canvasupload/';

class cRegistrationForm extends RPCFormBase
{
  constructor(node)
  {
    super(node);
    this.initPagination();

    let reviewbtn = this.node.querySelector("#submitforreview");
    if( reviewbtn )
    {
      reviewbtn.addEventListener("click", ev => {
        this.validateAndSubmit({review:true});
      });
    }

    for( let btnnode of node.querySelectorAll(".wh-form__button--save") )
      btnnode.addEventListener("click", ev => this.validateAndSubmit());

    //Image editor
    for( let imgnode of node.querySelectorAll(".canvasupload") )
      new CanvasUploadField(imgnode);
  }

  initPagination()
  {
    this.pages = this.node.querySelectorAll(".wh-form__page");
    if( this.pages.length < 3 ) //If just 2 pages (form and finsh page) then ignore
      return;

    let i = 0;
    this.activepage = 0;
    this.pagination = <ul class="wh-form__pagination" />;
    for( let node of this.pages )
    {
      if( node.classList.contains("wh-form__page--visible") )
        this.activepage = i;
      this.pagination.appendChild( <li title={node.dataset.title}>{ i == this.pages.length - 1 ? <span class="fal fa-flag-checkered"></span> : <span>{i+1}</span>}</li> );
      ++i;
    }

    this.node.classList.add("withpagination");

    this.pagination.addEventListener("click", ev => this.onPageClick(ev) );
    this.pagination.children[this.activepage].classList.add("active");

    this.node.insertBefore(this.pagination, this.node.children[0]);
    this.node.addEventListener("wh:form-pagechange", ev => this.onPageChange(ev.target));
  }

  async onPageClick(ev)
  {
    let curpage = this.pages[this.activepage];
    if( curpage.dataset.whFormPagerole && curpage.dataset.whFormPagerole == "thankyou" )
      return;

    let el = dompack.closest(ev.target, "li");
    if( !el || el.classList.contains("active") )
      return;

    for( let i = 0; i < this.pagination.children.length; ++i )
    {
      if( this.pagination.children[i] == el )
      {
        let validationstatus = await this.validate(this.pages[this.activepage]);
        if( !validationstatus.valid )
          return;

        if( el.classList.contains("previous") )
          this.gotoPage(i); //previous page(s)
        else if( i < this.pagination.children.length - 1 )
          this.gotoPage(i);//next page(s)
        else //Last page
          this.gotoPage(i);

        return;
      }
    }
  }

  onPageChange(page)
  {
    if( !this.pagination )
      return;

    let idx = this.activepage;
    let i = 0;
    let isprevious = true;
    for( let node of this.pages )
    {
      if( node == page )
      {
        idx = i;
        this.pagination.children[i].classList.add("active");
        this.pagination.children[i].classList.remove("previous");
        isprevious = false;
      }
      else
      {
        this.pagination.children[i].classList.remove("active");
        if( isprevious )
          this.pagination.children[i].classList.add("previous");
        else
          this.pagination.children[i].classList.remove("previous");
      }

      ++i;
    }

    this.activepage = idx;

    this.validateAndSubmit();
  }

  onSubmitFailed(errors)
  {
    let msgs = [];
    for( let i = 0; i < errors.length; ++i )
    {
      if( errors[i].message != "" )
        msgs.push(errors[i].message);
    }
    if( msgs.length )
      alert(msgs.join("\n"));
    else
      alert("Unknown error");
  }

  onSubmitSuccess(result)
  {
    if( result.success && result.reload )
      window.location.reload(true);
  }
}

registerHandler("kiskeya:registrationform", node => new cRegistrationForm(node));
