import "./photoalbum.scss";
import * as dompack from 'dompack';
import * as preload from 'dompack/extra/preload';
import * as swipe from 'dompack/browserfix/swipelistener';

class cPhotoalbum
{
  constructor(node)
  {
    this.photos = JSON.parse(node.dataset.photos);
    this.title = node.dataset.title;

    node.addEventListener("click", ev => this.showOverlay(ev));
    this.keyboardfn = this.onKeyUp.bind(this);
    this.resizefn = this.setImageSize.bind(this);
  }

  onKeyUp(ev)
  {
    if( ev.keyCode == 27 ) //ESC
      this.hideOverlay();
    else if( ev.keyCode == 37 ) //left
      this.previousImage();
    else if( ev.keyCode == 39 ) //right
      this.nextImage();
  }

  previousImage()
  {
    if( this.activeidx > 0 )
      this.gotoImage(this.activeidx - 1);
  }

  nextImage()
  {
    if( this.activeidx < this.photos.length - 1)
      this.gotoImage(this.activeidx + 1);
  }

  gotoImage(idx)
  {
    if( this.activeidx == idx || this.busy )
      return;

    if( this.previousnode )
    {
      if( idx == 0 )
        this.previousnode.classList.add("disabled");
      else
        this.previousnode.classList.remove("disabled");
    }

    if( this.nextnode )
    {
      if( idx >= this.photos.length - 1 )
        this.nextnode.classList.add("disabled");
      else
        this.nextnode.classList.remove("disabled");
    }

    this.busy = true;
    this.nextimage = this.createImage( idx );

    this.nextimage.style.transform = "translate3d(" + ( idx > this.activeidx ? this.viewport.x : -this.viewport.x ) + "px,0,0)";
    this.slidescontainer.appendChild(this.nextimage);
    this.setImageSize({ type : "newimage"});
    this.nextimage.clientWidth;//force css update
    this.nextimage.style.transform = "translate3d(0,0,0)";
    this.currentimage.style.transform = "translate3d(" + ( idx > this.activeidx ? -this.viewport.x : this.viewport.x ) + "px,0,0)";

    this.countnode.textContent = (1 +  idx) + "/" + this.photos.length;

    setTimeout(function(){
      this.activeidx = idx;
      this.slidescontainer.removeChild(this.currentimage);
      this.currentimage = this.nextimage;

      this.busy = false;
    }.bind(this), 500);
  }

  getWindowSize()
  {
    this.viewport = { x : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                    , y : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                    };
    return this.viewport;
  }

  async preloadImage( wrappernode, imgsrc )
  {
    let preloadedimage = await preload.promiseImage( imgsrc );
    if( preloadedimage && wrappernode )
    {
      wrappernode.classList.remove("image--loading");
      preloadedimage.node.style.opacity = 0;
      wrappernode.appendChild( preloadedimage.node );
      wrappernode.clientWidth;// force css update
      preloadedimage.node.style.opacity = 1;

      let resizenode = wrappernode.querySelector(".image__sizer");
      if( resizenode )
        dompack.empty(resizenode);//remove loading indicator
    }
  }

  createImage( idx )
  {
    let aspect = this.photos[idx].image.height / this.photos[idx].image.width;
    let imagenode =
      <div class="image image--loading"
           title={this.photos[idx].title}
           style={{ maxWidth: this.photos[idx].image.width + "px"
                  , maxHeight: this.photos[idx].image.height + "px"
                  , backgroundColor: this.photos[idx].bgcolor
                 }}>
        <div class="image__sizer" style={{paddingTop: (aspect * 100) + "%" }}>
          <span class="fa fa-circle-o-notch fa-spin" />
        </div>
        { this.photos[idx].title
            ? <div class="title">{this.photos[idx].title}</div>
            : null
        }
       </div>;//

    if( this.photos[idx].video )
    {
      imagenode.appendChild(<div class="video-playbtn" />);

      imagenode.addEventListener("click", ev => {
        if( imagenode.querySelector(".wh-video") )
          return;

        imagenode.appendChild(<div class="wh-video" data-video={this.photos[idx].video} data-video-options="{&#34;autoplay&#34;:1}" />);
        dompack.registerMissed(imagenode);
      });
    }
    this.preloadImage(imagenode, this.getWindowSize().x > 1024 ? this.photos[idx].image2.link : this.photos[idx].image.link);

    return imagenode;
  }

  setImageSize( ev )
  {
    if( !this.viewport || (ev && ev.type == "resize") )
      this.getWindowSize();

    let spacing = { x : 100, y : 140 };

    for( let node of this.overlay.querySelectorAll(".image") )
    {
      let w = node.style.maxWidth.replace(/[^0-9]/g, "");
      let h = node.style.maxHeight.replace(/[^0-9]/g, "");
      let aspect = h / w;
      if( w > this.viewport.x - spacing.x )
      {
        w = this.viewport.x - spacing.x;
        h = ~~(w * aspect);
      }
      if( h > this.viewport.y - spacing.y )
      {
        h = this.viewport.y - spacing.y;
        w = ~~(h / aspect);
      }
      node.style.width = w + "px";
      node.style.marginLeft = -w/2 + "px";
      node.style.marginTop = -h/2 + "px";
    }
  }

  showOverlay(ev)
  {
    this.activeidx = 0;

    this.countnode = <h2 class="counter">{1 + "/" + this.photos.length}</h2>;
    this.slidescontainer = <div class="slides" />;//

    this.overlay = <div class="photoalbum-overlay">
                     {this.countnode}
                     <div class="close" data-action="close"><i class="fal fa-times"></i></div>
                     <h2 class="photoalbum-overlay__title">{this.title}</h2>
                     {this.slidescontainer}
                   </div>;//
    if( this.photos.length > 1 )
    {
      this.previousnode = <div class="previous disabled" data-action="previous"><i class="fal fa-angle-left"></i></div>;//
      this.overlay.appendChild(this.previousnode);

      this.nextnode = <div class="next" data-action="next"><i class="fal fa-angle-right"></i></div>;//
      this.overlay.appendChild(this.nextnode);
    }

    swipe.enable(this.overlay);
    this.overlay.addEventListener("dompack:swipe", ev => {
      if( ev.detail.direction == "e" )
        this.previousImage();
      else if( ev.detail.direction == "w" )
        this.nextImage();
    });

    document.body.appendChild(this.overlay);
    this.overlay.addEventListener("click", ev => this.onOverlayClick(ev));

    document.documentElement.classList.add("hidescroll");

    this.currentimage = this.createImage(this.activeidx);
    this.slidescontainer.appendChild(this.currentimage);

    this.setImageSize({ type : "newimage"});

    window.addEventListener("resize",this.resizefn);
    document.body.addEventListener("keyup",this.keyboardfn);
  }

  onOverlayClick(ev)
  {
    let actionnode = dompack.closest(ev.target, "[data-action]");
    if( actionnode )
    {
      if( actionnode.dataset.action == "close" )
        this.hideOverlay();
      else if( actionnode.dataset.action == "previous" )
        this.previousImage();
      else if( actionnode.dataset.action == "next" )
        this.nextImage();
    }
  }

  hideOverlay(ev)
  {
    document.body.removeEventListener("keydown", this.keyboardfn);
    window.removeEventListener("resize",this.resizefn);

    document.documentElement.classList.remove("hidescroll");

    if( this.overlay )
      dompack.remove(this.overlay);
  }
}

dompack.register(".widget-photoalbum__container[data-photos]", node => new cPhotoalbum(node));
