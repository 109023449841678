import "./paymentform.scss";
import * as dompack from 'dompack';
import * as dialogapi from 'dompack/api/dialog';

class cPaymentFormAmountTabs
{
  constructor(node)
  {
    this.node = node;

    window.addEventListener("resize", ev => this.onResize());
    window.addEventListener("load", ev => this.onResize());
    this.onResize();
  }

  onResize()
  {
    //Reserve space for amount descriptions
    let descrnodes = this.node.querySelectorAll(".amountoption__description");
    if( descrnodes.length > 0 )
    {
      let minh = 0;
      for( let node of descrnodes )
      {
        node.style.minHeight = "";//first reset
        let h = node.clientHeight;
        if( h > minh )
          minh = h;
      }
      dompack.closest(descrnodes[0], ".amountoptions").style.paddingBottom = minh + "px";
      for( let node of descrnodes )
        node.style.minHeight = minh + "px";
    }
  }
}

dompack.register(".smallpaymentform .amountoptions", node => new cPaymentFormAmountTabs(node));
dompack.register("#paymentresult-message", node => {
  let dialog = dialogapi.createDialog();
  let closebtn = <div class="dialog-close"><i class="fal fa-times"></i></div>;//
  node.appendChild(closebtn);

  dialog.contentnode.appendChild(node);
  node.style.display = "block";

  closebtn.addEventListener("click", ev => { dialog.resolve();});

  dialog.runModal();
});
