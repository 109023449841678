import "./photosrow.scss";
import * as dompack from 'dompack';

class cPhotoRowCaptions
{
  constructor(node)
  {
    this.node = node;

    this.captions = node.querySelectorAll(".image__caption");
    if( !this.captions.length )
      return;

    this.closeFn = this.hideCaption.bind(this);

    this.node.addEventListener("click", ev => {
      let imgwrapper = dompack.closest(ev.target, ".image");
      if( imgwrapper && imgwrapper.title )
        this.showCaption( imgwrapper );
    });
  }

  hideCaption(ev)
  {
    if( ev && ev.type == "click" )
    {
      let node = dompack.closest(ev.target, ".image, .imagecaption-popup");
      if( node && node == this.captionnode || node == this.activeimage )
        return;
    }
    else if( ev && ev.type == "keyup" )
    {
      if( ev.keyCode != 27 )//ESC
        return;
    }

    this.node.removeEventListener("scroll", this.closeFn );
    window.removeEventListener("resize", this.closeFn );
    document.body.removeEventListener("click", this.closeFn );
    document.body.removeEventListener("keyup", this.closeFn );

    if( this.captionnode )
      this.captionnode.parentNode.removeChild(this.captionnode);

    this.activeimage = null;
    this.captionnode = null;
  }

  showCaption( imgwrapper )
  {
    if( imgwrapper == this.activeimage )
      return;

    this.activeimage = imgwrapper;

    let title = imgwrapper.title;

    let pos = imgwrapper.getBoundingClientRect();
    let bodypos = document.body.getBoundingClientRect();

    if( this.captionnode )
      this.captionnode.parentNode.removeChild(this.captionnode);
    else
    {
      this.node.addEventListener("scroll", this.closeFn );
      window.addEventListener("resize", this.closeFn );
      document.body.addEventListener("click", this.closeFn );
      document.body.addEventListener("keyup", this.closeFn );
    }

    this.captionnode = <div class="imagecaption-popup">
                          {title}
                          <span class="imagecaption-popup__beacon" />
                       </div>;//

    this.captionnode.style.bottom = bodypos.bottom - pos.top + "px";
    this.captionnode.style.left  = pos.left + (pos.width/2) + "px";

    document.body.appendChild(this.captionnode);
  }
}

dompack.register(".widget-photosrow", node => new cPhotoRowCaptions(node));
