import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';
import * as browser from "dompack/extra/browser";
import * as preload from 'dompack/extra/preload';
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log
import "@mod-publisher/js/analytics/ga4";
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import * as cookie from "dompack/extra/cookie";
import Ps from "perfect-scrollbar";
import "perfect-scrollbar/dist/css/perfect-scrollbar.css";
import './site.lang.json';
import './shared/forms/forms';
import './shared/rtd/rtd';
import './plankiskeya.scss';
import './pages/donate';
import './pages/dnakit';
import './pages/login';
import './pages/registration';
import './pages/searchfamily';
import './pages/search';
import './widgets/cta';
import './widgets/faq';
import './widgets/imagecta';
import './widgets/news';
import './widgets/photoalbum';
import './widgets/photosrow';
import './widgets/slideshow';
import './widgets/video';
import './widgets/twocol';
//import './debug'; //Debug layout grid
import './header';
import './footer';

window.event_supportspassive = false;
document.createElement("div").addEventListener("test", _ => {}, { get passive() { window.event_supportspassive = true; }});

/*
  Load images for elements where data-loadimage is set (format same as srcset) when visible in viewport
  img element will be appended inside this element
*/
class cLazyLoadImages
{
  constructor()
  {
    this.refresh();

    if( window.event_supportspassive )
      window.addEventListener("scroll", ev => this.loadKnownImages(ev), { passive: true } );
    else
      window.addEventListener("scroll", ev => this.loadKnownImages(ev) );

    window.addEventListener("kiskeya:refreshcontent", ev => this.loadKnownImages(ev) );

    window.addEventListener("resize", ev => this.loadKnownImages(ev) );
    window.addEventListener("load", ev => this.loadKnownImages(ev) );
  }

  refresh()
  {
    this.items = [];
    for( let node of document.querySelectorAll("[data-loadimage]") )
      this.items.push(node);

    this.loadKnownImages();
  }

  loadKnownImages(ev)
  {
    if( !this.items.length )
      return;//Nothing todo

    if( !this.viewport || (ev && ev.type == "resize") )
    {
      this.viewport = { x : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
                      , y : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
                      };
    }

    for( let i = this.items.length - 1; i >= 0; --i )
    {
      if( !this.items[i].clientWidth || !this.items[i].dataset.loadimage)
        continue;//Probably hidden element or no loadimage, so ignore

      let pos = this.items[i].getBoundingClientRect();
      if( pos.bottom > 0 && pos.top < this.viewport.y )
      {
        let srcset = this.items[i].dataset.loadimage;
        this.preloadImage( this.items[i], srcset );
        //remove attribute so image is not loaded multipletimes incase of refresh
        this.items[i].removeAttribute("data-loadimage");
        this.items.splice(i,1);
      }
    }
  }

  async preloadImage( wrappernode, srcset )
  {
    let preloadedimage = await preload.promiseImage( srcset.split(" ")[0] );
    if( preloadedimage )
    {
      let title = wrappernode.getAttribute("title");
      preloadedimage.node.setAttribute("alt", title ? title : "");
      preloadedimage.node.setAttribute("srcset", srcset);
      preloadedimage.node.style.opacity = 0;
      preloadedimage.node.style.transition = "opacity 300ms";
      wrappernode.appendChild( preloadedimage.node );
      wrappernode.clientWidth;// force css update
      preloadedimage.node.style.opacity = 1;
    }
  }
}


class cPathNav
{ //Shorten pathnav if it doesn't fit
  constructor( node )
  {
    this.node = node;
    this.lastitem = this.node.children[ this.node.children.length - 1 ];

    window.addEventListener("load", ev => this.onResize() );
    window.addEventListener("resize", ev => this.onResize() );
    this.onResize();
  }

  onResize()
  { //Shorten pathnav if it doesn't fit but always first amd last 2 items
    //First reset
    this.node.classList.add("overflow");//Workaround for IE so we can calculate overflow

    this.node.classList.remove("shorten");
    for( let i = 1; i < this.node.children.length - 2; ++i )
      this.node.children[i].classList.remove("hide");

    this.node.clientWidth;//force css refresh

    let hiddencount = 0;
    let x_item = this.lastitem.getBoundingClientRect().right;
    let x_base = this.node.getBoundingClientRect().right;

    if( x_item > x_base )
    { //Doesn't fit
      for( let i = 1; i < this.node.children.length - 2; ++i )
      {
        this.node.children[i].classList.add("hide");
        ++hiddencount;
        this.node.clientWidth;//force css refresh

        let x_item = this.lastitem.getBoundingClientRect().right;
        if(x_item <= x_base )
          break;
      }

      if( hiddencount )
        this.node.classList.add("shorten");
      this.node.classList.remove("overflow");
    }
  }
}


dompack.onDomReady(() =>
{
  openLinksInNewWindow({ extensions : ["pdf"] });

  const language = whintegration.config.locale.split("-")[0];
  cookie.write("kiskeya-lang", language, {duration:365});

  new cLazyLoadImages();
});

window.addEventListener("load", ev => {
  //Only allow css transitions after load because of chrome
  document.documentElement.classList.remove("beforeload");
});

//Used by photosrow
dompack.register(".horizontal-scroller", node => {
  Ps.initialize(node, { swipePropagation: true
                      , wheelPropagation: true
                      , suppressScrollY : true
                      });
  Ps.update(node);
  let scrollxnode = node.querySelector(".ps-scrollbar-x-rail");

  window.addEventListener("load", ev => {
    scrollxnode.setAttribute("style",""); //workaround for correct resizing scrollbar
    Ps.update(node);
  });

  window.addEventListener("resize", ev => {
    scrollxnode.setAttribute("style",""); //workaround for correct resizing scrollbar
    Ps.update(node);
  });
});

//Pathnav
dompack.register("#pathnav > ol", node => new cPathNav(node));

//Scroll indicator used in headerphoto on homepage
dompack.register(".scrollindicator", node => {
  let anchor = document.getElementById("articleanchor");

  node.addEventListener("click", ev => {
    if(["ie","edge"].includes(browser.getName()))
      anchor.scrollIntoView();
    else
      anchor.scrollIntoView({"behavior":"smooth"});
  });
});
