import "./searchfamily.scss";
import * as dompack from 'dompack';
import * as dialogapi from 'dompack/api/dialog';

class cPersonSearch
{
  constructor(node)
  {
    this.node = node;
    this.items = this.node.querySelectorAll(".person");
    this.baseurl = document.location.href.split(/[?#]+/)[0];

    this.form = document.getElementById("personfilterform");
    this.form.addEventListener("submit", ev => {
      ev.preventDefault();
    });

    this.form.addEventListener("change", ev => this.onChange(ev) );

    let typefilter = this.getUrlParam("filter");
    if( typefilter != "" && !this.form.filter.querySelector("option[value='" + typefilter + "']"))
      typefilter = "";

    let countryfilter = this.getUrlParam("country");
    if( countryfilter != "" && !this.form.country.querySelector("option[value='" + countryfilter + "']"))
      countryfilter = "";

    if( typefilter || countryfilter )
    {
      this.form.filter.value = typefilter;
      this.form.country.value = countryfilter;
      this.onChange();
    }
  }

  onChange()
  {
    let totalcount = 0;
    for( let node of this.items )
    {
      let ismatch = true;

      if( this.form.filter.value == "family" )
        ismatch = node.dataset.for == "FAMILY";
      else if( this.form.filter.value == "adoptee" )
        ismatch = node.dataset.for == "ADOPTEE";

      if( ismatch && this.form.country.value )
        ismatch = node.dataset.country == this.form.country.value;

      if( ismatch )
        ++totalcount;

      node.style.display = ismatch ? "" : "none";
    }

    dompack.toggleClass(this.node, "searchfamily-results--noresults", !totalcount);

    if( history.replaceState )
    {
      let param = [];
      if( this.form.filter.value )
        param.push("filter=" + this.form.filter.value);
      if( this.form.country.value )
        param.push("country=" + this.form.country.value);
      history.replaceState(null, "", this.baseurl + ( param.length ? "?" + param.join("&") : "") + location.hash);
    }

    dompack.dispatchCustomEvent(window, "kiskeya:refreshcontent", { bubbles    : true
                                                                  , cancelable : false
                                                                  , detail     : {}
                                                                  });
  }

  getUrlParam(name)
  {
    let urlparamstr = location.search.replace(/\+/g,"%20");
    let val  = ( new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)') ).exec(urlparamstr);
    return val ? decodeURIComponent(val[1]) : "";
  }
}

dompack.register(".searchfamily-results", node => new cPersonSearch(node));
dompack.register(".searchfamily-detailsmeta__contact__button", node => {
  node.addEventListener("click", ev => {
    let formnode = dompack.qS(".searchfamily-detailsmeta__contact__form");
    if( !formnode )
      return;

    let dialog = dialogapi.createDialog({ borrow: formnode, allowcancel:true });

    let closebtn = <div class="dialog-close"><i class="fal fa-times"></i></div>;//
    dialog.contentnode.appendChild(closebtn);
    closebtn.addEventListener("click", ev => dialog.resolve() );

    dialog.runModal();
  });
});
