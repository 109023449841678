import "./slideshow.scss";
import * as dompack from 'dompack';
import * as swipe from 'dompack/browserfix/swipelistener';

class cSlideshow
{
  constructor(node)
  {
    this.slides = node.querySelectorAll(".widget-slideshow__container__slides__slide");
    if( this.slides.length < 2 )
      return;

    swipe.enable(node);
    node.addEventListener("dompack:swipe", ev => {
      if( ev.detail.direction == "e" )
        this.gotoSlide(this.idx - 1);
      else if( ev.detail.direction == "w" )
        this.gotoSlide(this.idx + 1);
    });

    this.idx = 0;
    this.jmpbtns = node.querySelectorAll(".widget-slideshow__container__jumpbuttons > span");
    node.addEventListener("click", ev => this.onClick(ev));
    node.addEventListener("keyup", ev => {
      if( ev.keyCode == 37 )//arrow left
        this.gotoSlide(this.idx - 1);
      else if( ev.keyCode == 39 )//arrow right
        this.gotoSlide(this.idx + 1);
    });
  }

  onClick(ev)
  {
    let actionnode = dompack.closest(ev.target, "[data-action]");
    if( !actionnode )
      return;

    if( actionnode.dataset.action.replace(/[^0-9]/g, "") == actionnode.dataset.action )
      this.gotoSlide(1*ev.target.dataset.action);  //jump button
    else if( actionnode.dataset.action == "previous" )
      this.gotoSlide(this.idx - 1);
    else if( actionnode.dataset.action == "next" )
      this.gotoSlide(this.idx + 1);
    else if( actionnode.dataset.action == "playvideo" )
    {
      if( !actionnode.classList.contains("wh-video") )
      {
        actionnode.classList.add("wh-video");
        dompack.registerMissed(actionnode);
        this.activevideo = actionnode;
      }
    }
  }

  gotoSlide( idx )
  {
    if( idx < 0 )
      idx = this.slides.length - 1;
    idx = idx%this.slides.length;
    if( idx == this.idx )
      return;

    if( this.activevideo )
    {

      this.activevideo.classList.remove("wh-video");
      dompack.empty(this.activevideo);

      //Workaround so dompack.registerMissed will pickup video again
      let cp = this.activevideo.cloneNode(true);
      let pnode = this.activevideo.parentNode;
      pnode.removeChild(this.activevideo);
      pnode.appendChild(cp);

      this.activevideo = null;
    }

    this.slides[this.idx].classList.remove("active");
    this.jmpbtns[this.idx].classList.remove("active");
    this.idx = idx;
    this.slides[this.idx].classList.add("active");
    this.jmpbtns[this.idx].classList.add("active");
  }
}

dompack.register(".widget-slideshow__container", node => new cSlideshow(node));
